/* Sticky footer styles
-------------------------------------------------- */

.footer {

    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    line-height: 60px;
    /* Vertically center the text there*/
}


/* Custom page CSS
-------------------------------------------------- */

/* Not required for template or sticky footer method. */

.footer>.container {
    padding-right: 15px;
    padding-left: 15px;
}

code {
    font-size: 80%;
}
