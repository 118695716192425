/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

.carousel,
.item,
.carousel-item {
    height: 100%;

}

.carousel-inner {
    height: 100%;
}

/* Background images are set within the HTML using inline CSS, not here */

.fill {
    width: 100%;
    height: 100%;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}


.carousel-indicators li.active {
    background-color: $blue;
}

.carousel-indicators li {
    background-color: rgba(0, 0, 0, 0.5);
}
